<template>
    <div class="account-page">
        <div class="container-fluid">
            
            <div v-if="me">
               
                <h1>Moje konto</h1>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="row">
                            <div class="col-12 col-md-5">Imię:</div>
                            <div class="col-12 col-md-7"><b>{{ me.name}}</b></div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-5">Nazwisko:</div>
                            <div class="col-12 col-md-7"><b>{{ me.surname }}</b></div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-5">Email:</div>
                            <div class="col-12 col-md-7"><b>{{ me.email }}</b></div>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <router-link :to="{name: 'AdminChangeData'}" class="mr-2"><button class="btn btn-primary" >Zmień dane</button></router-link>
                        <br />
                        <router-link :to="{name: 'AdminChangePassword'}" ><button class="btn btn-secondary mt-3" >Zmień hasło</button></router-link>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters} from "vuex";

export default {
    name: 'customerAccount',
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
        ...mapGetters({ me : 'auth/me'}),
    },
    methods: {
       
    }
}
</script>
